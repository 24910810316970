import React,{Component,Suspense,lazy} from 'react';

import { connect } from "react-redux";
import { Route, Router, BrowserRouter, Switch,Redirect,useHistory,withRouter,Link } from 'react-router-dom';


/*import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/js/bootstrap.min.js";*/

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import $ from 'jquery';
import Popper from 'popper.js';

import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/custom.css";
import "./assets/css/admin.css";
import "./App.css";

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

import { history } from './helpers/history';


/*import AdminLogin from "./components/admin/Login";
import AdminDashboard from "./components/admin/Dashboard";

import AdminCountryList from "./components/admin/country/CountryList";
import AdminCountryAdd from "./components/admin/country/CountryAdd";
import AdminCountryEdit from "./components/admin/country/CountryEdit";

import AdminStateList from "./components/admin/state/StateList";
import AdminStateAdd from "./components/admin/state/StateAdd";
import AdminStateEdit from "./components/admin/state/StateEdit";

import AdminCityList from "./components/admin/city/CityList";
import AdminCityAdd from "./components/admin/city/CityAdd";
import AdminCityEdit from "./components/admin/city/CityEdit";

import AdminThematicList from "./components/admin/thematic/ThematicList";
import AdminThematicAdd from "./components/admin/thematic/ThematicAdd";
import AdminThematicEdit from "./components/admin/thematic/ThematicEdit";

import AdminDetailedThematicList from "./components/admin/detailedthematic/DetailedThematicList";
import AdminDetailedThematicAdd from "./components/admin/detailedthematic/DetailedThematicAdd";
import AdminDetailedThematicEdit from "./components/admin/detailedthematic/DetailedThematicEdit";

import AdminAuditorList from "./components/admin/auditor/AuditorList";
import AdminAuditorAdd from "./components/admin/auditor/AuditorAdd";
import AdminAuditorEdit from "./components/admin/auditor/AuditorEdit";

import AdminNgoList from "./components/admin/ngo/NgoList";
import AdminNgoEdit from "./components/admin/ngo/NgoEdit";

import AuditorLogin from "./components/auditor/Login";
import AuditorDashboard from "./components/auditor/Dashboard";

import AuditorNgoList from "./components/auditor/ngo/NgoList";
import AuditorNgoEdit from "./components/auditor/ngo/NgoEdit";
*/

/*const AdminLogin=lazy(() =>import ('./components/admin/Login'));
const AdminDashboard=lazy(() =>import ('./components/admin/Dashboard'));

const AdminCountryList =lazy(() =>import ('./components/admin/country/CountryList'));
const AdminStateList =lazy(() =>import ('./components/admin/state/StateList'));
const AdminThematicList =lazy(() =>import ('./components/admin/thematic/ThematicList'));
const AdminCityList =lazy(() =>import ('./components/admin/city/CityList'));*/

const AdminLogin =lazy(() =>import ('./components/admin/Login'));
const AdminDashboard =lazy(() =>import ('./components/admin/Dashboard'));

const AdminCountryList =lazy(() =>import ('./components/admin/country/CountryList'));
const AdminCountryAdd =lazy(() =>import ('./components/admin/country/CountryAdd'));
const AdminCountryEdit =lazy(() =>import ('./components/admin/country/CountryEdit'));

const AdminStateList =lazy(() =>import ('./components/admin/state/StateList'));
const AdminStateAdd =lazy(() =>import ('./components/admin/state/StateAdd'));
const AdminStateEdit =lazy(() =>import ('./components/admin/state/StateEdit'));

const AdminCityList =lazy(() =>import ('./components/admin/city/CityList'));
const AdminCityAdd =lazy(() =>import ('./components/admin/city/CityAdd'));
const AdminCityEdit =lazy(() =>import ('./components/admin/city/CityEdit'));

const AdminThematicList =lazy(() =>import ('./components/admin/thematic/ThematicList'));
const AdminThematicAdd =lazy(() =>import ('./components/admin/thematic/ThematicAdd'));
const AdminThematicEdit =lazy(() =>import ('./components/admin/thematic/ThematicEdit'));

const AdminDetailedThematicList =lazy(() =>import ('./components/admin/detailedthematic/DetailedThematicList'));
const AdminDetailedThematicAdd =lazy(() =>import ('./components/admin/detailedthematic/DetailedThematicAdd'));
const AdminDetailedThematicEdit =lazy(() =>import ('./components/admin/detailedthematic/DetailedThematicEdit'));

const AdminAuditorList =lazy(() =>import ('./components/admin/auditor/AuditorList'));
const AdminAuditorAdd =lazy(() =>import ('./components/admin/auditor/AuditorAdd'));
const AdminAuditorEdit =lazy(() =>import ('./components/admin/auditor/AuditorEdit'));

const AdminSkillList =lazy(() =>import ('./components/admin/Skill/SkillList'));
const AdminSkillAdd =lazy(() =>import ('./components/admin/Skill/SkillAdd'));
const AdminSkillEdit =lazy(() =>import ('./components/admin/Skill/SkillEdit'));

const AdminRaiseErrorList =lazy(() =>import ('./components/admin/RaiseError/RaiseList'));
const AdminRaiseErrorAdd =lazy(() =>import ('./components/admin/RaiseError/RaiseErrorAdd'));
const AdminRaiseErrorEdit =lazy(() =>import ('./components/admin/RaiseError/RaiseErrorEdit'));

const AdminNgoList =lazy(() =>import ('./components/admin/ngo/NgoList'));
const AdminOrgList =lazy(() =>import ('./components/admin/ngo/OrgList'));
const AdminNgoEdit =lazy(() =>import ('./components/admin/ngo/NgoEdit'));
const AdminNgoDashboard=lazy(() => import('./components/admin/ngo/NgoDashboard'));

const AdminTcswList =lazy(() =>import ('./components/admin/ngo/TcswList'));
const AdminTcswadd =lazy(() =>import ('./components/admin/ngo/TcswAdd'));
const AdminTcswedit =lazy(() =>import ('./components/admin/ngo/TcswEdit'));

const AdminProductList =lazy(() =>import ('./components/admin/marketplace/ProductList'));
const AdminProductView =lazy(() =>import ('./components/admin/marketplace/ProductView'));
const AdminOrderList =lazy(() =>import ('./components/admin/marketplace/OrderList'));

const AdminUserList =lazy(() =>import ('./components/admin/user/UserList'));
const AdminUserView =lazy(() =>import ('./components/admin/user/UserViewDetails'));
const AdminUserEdit =lazy(() =>import ('./components/admin/user/UserEdit'));
const AdminUserFlaggedPosts=lazy(() =>import ('./components/admin/user/UserFlaggedPost'));

const AuditorLogin =lazy(() =>import ('./components/auditor/Login'));
const AuditorDashboard =lazy(() =>import ('./components/auditor/Dashboard'));

const AuditorNgoList =lazy(() =>import ('./components/auditor/ngo/NgoList'));
const AuditorNgoEdit =lazy(() =>import ('./components/auditor/ngo/NgoEdit'));

const ContactUs=lazy(() =>import('.//components/admin/Contactus'))

const WebsiteBannerList =lazy(() =>import ('./components/website/BannerList'));
const WebsiteBannerAdd =lazy(() =>import ('./components/website/BannerAdd'));
const WebsiteBannerEdit =lazy(() =>import ('./components/website/BannerEdit'));
const DonationInfo =lazy(() =>import ('./components/donations/NgowiseDonation'));
const NgoReviewDelete =lazy(() =>import ('./components/admin/ngo/NgoReviewDelete'));
const Commission =lazy(() =>import ('./components/donations/CommissionList'));
const CommissionEdit =lazy(() =>import ('./components/donations/CommissionEdit'));

const UserAllFlaggedPost =lazy(() =>import ('./components/admin/UserFlaggedPostAll'));
const NgoAllFlaggedPost =lazy(() =>import ('./components/admin/NgoFlaggedPostAll'));

const WebsiteKqYpList =lazy(() =>import ('./components/admin/kq/KqYPList'));
const WebsiteKqCxoList =lazy(() =>import ('./components/admin/kq/KqCXOList'));

const ProcamTopupList =lazy(() =>import ('./components/admin/ProcamTopupList'));

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      currentUser: undefined,
    };

  }

  componentDidMount() {
    const user = this.props.user;

    if (user) {
      this.setState({
        currentUser: user,
      });
    }
  }

  logOut() {
    this.props.dispatch(logout());
  }

  render() {
    const { currentUser } = this.state;

    return (
      <BrowserRouter>
        <Suspense fallback={<div></div>}>
        <Switch>
              <Route exact path="/" component={AdminLogin} />
              <Route exact path="/admin/login" component={AdminLogin} />
              <Route exact path="/admin/dashboard" component={AdminDashboard} />
              <Route exact path={["/admin/country", "/admin/country/list"]} component={AdminCountryList} />
              <Route exact path="/admin/country/add" component={AdminCountryAdd} />
              <Route exact path="/admin/country/edit/:country_id" component={AdminCountryEdit} />
              <Route exact path={["/admin/state", "/admin/state/list"]} component={AdminStateList} />
              <Route exact path="/admin/state/add" component={AdminStateAdd} />
              <Route exact path="/admin/state/edit/:state_id" component={AdminStateEdit} />
              <Route exact path={["/admin/city", "/admin/city/list"]} component={AdminCityList} />
              <Route exact path="/admin/city/add" component={AdminCityAdd} />
              <Route exact path="/admin/city/edit/:city_id" component={AdminCityEdit} />
              <Route exact path={["/admin/thematic", "/admin/thematic/list"]} component={AdminThematicList} />
              <Route exact path="/admin/thematic/add" component={AdminThematicAdd} />
              <Route exact path="/admin/thematic/edit/:cat_id" component={AdminThematicEdit} />
              <Route exact path={["/admin/detailedthematic", "/admin/detailedthematic/list"]} component={AdminDetailedThematicList} />
              <Route exact path="/admin/detailedthematic/add" component={AdminDetailedThematicAdd} />
              <Route exact path="/admin/detailedthematic/edit/:sub_cat_id" component={AdminDetailedThematicEdit} />
              <Route exact path={["/admin/auditor", "/admin/auditor/list"]} component={AdminAuditorList} />
              <Route exact path="/admin/auditor/add" component={AdminAuditorAdd} />
              <Route exact path="/admin/auditor/edit/:auditor_id" component={AdminAuditorEdit} />
              <Route exact path={["/admin/ngo", "/admin/ngo/list"]} component={AdminNgoList} />

              <Route exact path={["/admin/tcsw", "/admin/tcsw/list"]} component={AdminTcswList} />
               <Route exact path={["/admin/tcsw/add"]} component={AdminTcswadd} />
               <Route exact path={["/admin/tcsw/edit/:id"]} component={AdminTcswedit} />

              <Route exact path="/admin/org/list" component={AdminOrgList} />
              <Route exact path={["/admin/ngo/dashboard:ngoid", "/admin/ngo/ngoDashboard:ngoid"]} component={AdminNgoDashboard} />
              <Route exact path="/admin/ngo/edit/:id" component={AdminNgoEdit} />
              <Route exact path={["/admin/user", "/admin/user/list"]} component={AdminUserList} />
              <Route exact path="/admin/user/list/:id" component={AdminUserView} />
              <Route exact path="/admin/user/edit/:id" component={AdminUserEdit} />
              <Route exact path="/admin/user/userFlaggedPost/:id" component={AdminUserFlaggedPosts} />

              <Route exact path="/admin/marketplace/productlist" component={AdminProductList} />
              <Route exact path="/admin/marketplace/product/view/:id" component={AdminProductView} />
              <Route exact path="/admin/marketplace/orderlist" component={AdminOrderList} />
              
              
              
              <Route exact path={["/auditor", "/auditor/", "/auditor/login"]} component={AuditorLogin} />
              <Route exact path="/auditor/dashboard" component={AuditorDashboard} />
              <Route exact path={["/auditor/ngo", "/auditor/ngo/list"]} component={AuditorNgoList} />
              <Route exact path="/auditor/ngo/edit/:ngo_id" component={AuditorNgoEdit} />
              <Route exact path="/admin/contactus" component={ContactUs} />
              <Route exact path={["/admin/skill", "/admin/skill/list"]} component={AdminSkillList} />
              <Route exact path="/admin/skill/add" component={AdminSkillAdd} />
              <Route exact path="/admin/skill/edit/:skill_id" component={AdminSkillEdit} />

              <Route exact path={["/admin/raiseError/List", "/admin/skill/list"]} component={AdminRaiseErrorList} />
              <Route exact path="/admin/raiseError/add" component={AdminRaiseErrorAdd} />
              <Route exact path="/admin/raiseError/edit/:query_id" component={AdminRaiseErrorEdit} />

              <Route exact path={["/website/banner/List", "/admin/skill/list"]} component={WebsiteBannerList} />
              <Route exact path="/website/banner/add" component={WebsiteBannerAdd} />
              <Route exact path="/website/banner/edit/:banner_id" component={WebsiteBannerEdit} />
              <Route exact path="/donationsInfo" component={DonationInfo} />
			        <Route exact path="/NgoReviewList" component={NgoReviewDelete} />
              <Route exact path="/commission" component={Commission} />
			        <Route exact path="/commission/edit" component={CommissionEdit} />
            
              <Route exact path="/admin/user/flagged" component={UserAllFlaggedPost} />
              <Route exact path="/admin/ngo/flagged" component={NgoAllFlaggedPost} />

              <Route exact path={"/admin/kq/yplist"} component={WebsiteKqYpList} />
              <Route exact path={"/admin/kq/cxolist"} component={WebsiteKqCxoList} />

              <Route exact path={"/admin/topupdonationlist"} component={ProcamTopupList} />
              
              
            </Switch>
      </Suspense>
  </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);
